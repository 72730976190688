<script setup lang="ts">
import { useI18n } from "vue-i18n";
import GreyLayout from "@/layouts/GreyLayout.vue";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/logo-panel-930.png"
      width="930"
      height="930"
      :alt="t('panel-img-alt')"
      class="mb-10 mr-10 h-auto w-60"
    >

    <h1 class="mb-6 text-2xl text-[#4d4f53] md:text-4xl">
      {{ t("panel-success-title") }}
    </h1>

    <p v-sanitize="t('panel-success-intro')" class="mb-6 font-bold" />
    <p v-sanitize="t('panel-success-message')" class="mb-6" />
    <p v-sanitize="t('panel-success-link')" />
  </GreyLayout>
</template>
