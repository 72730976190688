import createDOMPurify from "dompurify";
import type { ObjectDirective } from "vue";

const TEMP_TARGET = "data-temp-href-target";

const DOMPurify = createDOMPurify();

DOMPurify.addHook("beforeSanitizeAttributes", (node) => {
  if (node.tagName === "A") {
    if (node.hasAttribute("target")) {
      node.setAttribute(TEMP_TARGET, node.getAttribute("target") as string);
    }
  }
});

DOMPurify.addHook("afterSanitizeAttributes", (node) => {
  if (node.tagName === "A" && node.hasAttribute(TEMP_TARGET)) {
    node.setAttribute("target", node.getAttribute(TEMP_TARGET) as string);
    node.removeAttribute(TEMP_TARGET);
    if (node.getAttribute("target")?.toLowerCase() === "_blank") {
      node.setAttribute("rel", "noreferrer,noopener");
    }
  }
});

export const sanitize: ObjectDirective<HTMLElement, string> = {
  beforeMount: (el, binding) => {
    el.innerHTML = DOMPurify.sanitize(binding.value);
  },
};
