<script setup lang="ts">
import { computed, ref } from "vue";
import { useFieldError } from "vee-validate";
import InputCheck from "./InputCheck.vue";
import type { RailwayOption } from "@/types";

const props = defineProps<{
  name: string;
  rerOptions: RailwayOption[];
  transilienOptions: RailwayOption[];
}>();

const error = useFieldError(props.name);

const touched = ref(false);

const transilienOptions1 = computed(() =>
  props.transilienOptions.filter(
    (_, index) => index < props.transilienOptions.length / 2,
  ),
);

const transilienOptions2 = computed(() =>
  props.transilienOptions.filter(
    (_, index) => index >= props.transilienOptions.length / 2,
  ),
);
</script>

<template>
  <div>
    <h2 class="current--title mb-4">
      <slot />
    </h2>

    <p v-if="error && touched" class="mb-2 text-sm text-red-500">
      {{ error }}
    </p>

    <div class="sm:flex-r flex flex-row">
      <div class="flex flex-1 flex-col items-start">

        <InputCheck
          v-for="rer of rerOptions"
          :key="rer.value"
          :name="name"
          :disable-errors="true"
          :value="rer.value"
          class="mb-3"
          @touched="touched = true"
        >
          <img
            height="20"
            :src="rer.src"
            unselectable="on"
            width="40"
            :alt="rer.alt"
          >
        </InputCheck>
      </div>

      <div class="flex-1">

        <div class="flex">
          <div class="flex flex-1 flex-col items-start">
            <InputCheck
              v-for="rer of transilienOptions1"
              :key="rer.value"
              :name="name"
              :disable-errors="true"
              class="mb-3"
              :value="rer.value"
              @change="touched = true"
            >
              <img
                height="20"
                :src="rer.src"
                unselectable="on"
                width="40"
                :alt="rer.alt"
              >
            </InputCheck>
          </div>

          <div class="flex flex-1 flex-col items-start">
            <InputCheck
              v-for="rer of transilienOptions2"
              :key="rer.value"
              :name="name"
              :disable-errors="true"
              class="mb-3"
              :value="rer.value"
              @change="touched = true"
            >
              <img
                height="20"
                :src="rer.src"
                unselectable="on"
                width="40"
                :alt="rer.alt"
              >
            </InputCheck>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
