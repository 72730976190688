import { type Ref, ref } from "vue";
import { useApi } from "./useApi";
import type { RailwayOption, SelectInputOption } from "@/types";
import { removeAccents } from "@/helpers/accents";

export function useCompanySizeOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<SelectInputOption[]>([]);
  api
    .getCompanySizes()
    .then((list) => {
      options.value = list;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return { companySizeOptions: options };
}

export function useFrequencyOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<SelectInputOption[]>([]);
  api
    .getFrequencies()
    .then((list) => {
      options.value = list;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return { frequencyOptions: options };
}

export function useStationOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<SelectInputOption[]>([]);
  api
    .getStations()
    .then((list) => {
      const noAccentList = list.map(({ label, value }) => ({
        label: removeAccents(label),
        value,
      }));
      noAccentList.sort((a, b) => a.label.localeCompare(b.label));
      options.value = noAccentList;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return {
    stationOptions: options,
    stationTest: async (value?: string) =>
      Promise.resolve(
        !value || !!options.value.find(option => option.label === value),
      ),
    getStationId: (name?: string) =>
      name
        ? options.value.find(option => option.label === name)?.value
        : undefined,
    getStationName: (id?: string) =>
      id
        ? options.value.find(option => option.value === id)?.label
        : undefined,
  };
}

export function useTransportTicketOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<SelectInputOption[]>([]);
  api
    .getTransportTickets()
    .then((list) => {
      options.value = list;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return {
    transportTicketOptions: options,
    transportTicketTest: async (value?: string) =>
      Promise.resolve(
        !value || !!options.value.find(option => option.value === value),
      ),
  };
}

export function useRerOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<RailwayOption[]>([]);
  api
    .getRers()
    .then((list) => {
      options.value = list;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return { rerOptions: options };
}

export function useTransilienOptions(errorMessage: Ref<string>) {
  const api = useApi();
  const options = ref<RailwayOption[]>([]);
  api
    .getTransiliens()
    .then((list) => {
      options.value = list;
    })
    .catch((error: Error) => {
      errorMessage.value = error.message;
    });
  return { transilienOptions: options };
}
