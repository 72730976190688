<script setup lang="ts">
import { useField } from "vee-validate";
import { type InputHTMLAttributes, watch } from "vue";
import { vMaska } from "maska";

const props = defineProps<{
  name: string;
  inputAttr?: InputHTMLAttributes;
  label?: string;
  uppercase?: boolean;
  mask?: string;
  transform?: (word: string) => string;
}>();

const rand = Math.random().toString();

const { handleChange, value, errors, errorMessage } = useField<
  string | undefined
>(props.name, undefined, {});

function onInput(val: string) {
  handleChange(val, errors.value.length > 0);
}

watch(value, (val) => {
  if (val && props.transform && props.transform(val) !== val) {
    value.value = props.transform(val);
  }
});
</script>

<template>
  <div>
    <label
      v-if="label !== undefined"
      v-sanitize="label"
      :for="rand + name"
      class="mb-2 inline-block"
    />

    <p v-if="errors.length" class="mb-2 ml-1 inline-block text-sm text-red-500">
      {{ errorMessage }}
    </p>

    <input
      v-if="mask"
      :id="rand + name"
      v-model="value"
      v-bind="{ type: 'text', ...(inputAttr || {}) }"
      v-maska
      :name="name"
      class="h-10 w-full bg-input px-3 py-2 text-sm"
      :class="{ uppercase }"
      :data-maska="mask"
      @input="onInput(($event.target as HTMLInputElement).value)"
    >
    <input
      v-else
      :id="rand + name"
      v-model="value"
      v-bind="{ type: 'text', ...(inputAttr || {}) }"
      :name="name"
      class="h-10 w-full bg-input px-3 py-2 text-sm"
      :class="{ uppercase }"
      @input="onInput(($event.target as HTMLInputElement).value)"
    >
  </div>
</template>

<style lang="scss" scoped></style>
