import { createI18n } from "vue-i18n";
import { fetchRetry } from "@/helpers/fetchRetry";

// /**
//  * Convert i18n messages style to API translates style
//  */
// function messagesToApiKeys(message: unknown) {
//   const array = [] as { key: string; value: string }[];
//   const recur = (obj: unknown, key: string = "") => {
//     console.log(obj, key, obj instanceof String);
//     if (typeof obj === typeof "string") {
//       array.push({ key, value: String(obj) });
//     } else if (obj instanceof Object) {
//       Object.keys(obj).forEach((name) => {
//         recur(
//           (obj as unknown as { [key: string]: string })[name],
//           key ? `${key}-${name}` : name
//         );
//       });
//     }
//   };

//   return JSON.stringify(recur(message), null, 2);
// }

/**
 * Init i18n plugin
 */
export function createI18nConf(path: string, callback: () => void) {
  const i18n = createI18n({
    locale: "fr",
    legacy: false,
    warnHtmlMessage: false,
  });

  async function loadTranslations() {
    try {
      const response = await fetchRetry(path);
      const list: { key: string; value: string }[] = await response.json();

      // Convert API translations to i18n messages
      const messages: { [key: string]: string } = {};
      list.forEach(({ key, value }) => {
        messages[key] = value;
      });
      i18n.global.setLocaleMessage("fr", messages);
    }
    catch (error) {
      console.error("Languages not loaded");
    }
    callback();
  }

  loadTranslations();

  return i18n;
}
