<script setup lang="ts">
import { useI18n } from "vue-i18n";
import GreyLayout from "@/layouts/GreyLayout.vue";
import PanelForm from "@/components/FormPanel.vue";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/logo-panel-930.png"
      width="930"
      height="930"
      :alt="t('panel-img-alt')"
      class="mb-10 mr-10 h-auto w-48 md:float-left"
    >

    <h1 class="my-6 text-xl text-[#4d4f53] md:text-4xl">
      {{ t("panel-form-title") }}
    </h1>

    <p v-sanitize="t('panel-form-p1')" class="mb-6" />
    <p v-sanitize="t('panel-form-p2')" class="mb-6" />

    <PanelForm />
  </GreyLayout>
</template>
