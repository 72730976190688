<script setup lang="ts">
import { useField } from "vee-validate";
import type { InputHTMLAttributes } from "vue";

const props = defineProps<{
  name: string;
  inputAttr?: InputHTMLAttributes;
  label?: string;
  list?: { label: string; value: string }[];
}>();
const { handleChange, value, errors, errorMessage } = useField(props.name, {
  initialValue: "",
});
const rand = Math.random().toString();

function onInput(val: string) {
  handleChange(val, errors.value.length > 0);
}
</script>

<template>
  <div>
    <h2 v-if="label !== undefined" class="mb-2">
      {{ label }}
    </h2>

    <p v-if="errors.length" class="text-sm text-red-500">
      {{ errorMessage }}
    </p>

    <label
      v-for="input of list || []"
      :key="rand + input.value"
      :for="rand + input.value"
      class="mb-2 flex cursor-pointer items-center gap-1"
    >
      <input
        :id="rand + input.value"
        v-bind="inputAttr || {}"
        v-model="value"
        :name="name"
        type="radio"
        :value="input.value"
        @input="onInput(($event.target as HTMLInputElement).value)"
      >
      <span class="flex-1">{{ input.label }}</span>
    </label>
  </div>
</template>

<style lang="scss" scoped></style>
