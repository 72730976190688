<script setup lang="ts">
import { useI18n } from "vue-i18n";
import GreyLayout from "@/layouts/GreyLayout.vue";
import B2bForm from "@/components/FormB2b.vue";

const { t } = useI18n();
</script>

<template>
  <GreyLayout>
    <img
      src="../assets/images/banner-b2b-1024.png"
      srcset="
        ../assets/images/banner-b2b-512.png  0.5x,
        ../assets/images/banner-b2b-1024.png 1x,
        ../assets/images/banner-b2b-2048.png 2x,
        ../assets/images/banner-b2b-4096.png 4x
      "
      width="1024"
      height="190"
      :alt="t('b2b-img-alt')"
      class="mb-10 w-full"
    >

    <h1 class="mb-6 text-xl text-[#4d4f53] md:text-2xl">
      {{ t("b2b-form-title") }}
    </h1>

    <p v-sanitize="t('b2b-form-intro')" class="mb-6" />

    <B2bForm />
  </GreyLayout>
</template>
