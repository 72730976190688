<script setup lang="ts">
import { useField } from "vee-validate";
import type { InputHTMLAttributes } from "vue";

const props = withDefaults(
  defineProps<{
    name: string;
    inputAttr?: InputHTMLAttributes;
    label?: string;
    defaultOption?: string;
    options: { value: string; label: string }[];
    uppercase?: boolean;
    required?: boolean;
  }>(),
  {
    required: true,
    inputAttr: undefined,
    label: "",
    defaultOption: "",
  },
);

const rand = Math.random().toString();

const { handleChange, errors, errorMessage, value } = useField(
  props.name,
  undefined,
  {
    initialValue: undefined,
  },
);

function onInput(val: string) {
  handleChange(val, errors.value.length > 0);
}
</script>

<template>
  <div>
    <label
      v-if="label"
      v-sanitize="label"
      :for="rand + name"
      class="mb-2 inline-block"
    />

    <p v-if="errors.length" class="ml-1 inline-block text-sm text-red-500">
      {{ errorMessage }}
    </p>

    <div class="flex content-center">
      <select
        :id="rand + name"
        v-bind="inputAttr || {}"
        v-model="value"
        :name="name"
        class="arrow-right h-10 w-full appearance-none bg-input py-2 pl-3 pr-10 text-sm"
        :class="{ uppercase }"
        @input="onInput(($event.target as HTMLInputElement).value)"
      >
        <option
          v-if="defaultOption"
          v-sanitize="defaultOption"
          disabled
          selected
          value=""
        />
        <option
          v-for="option of options"
          :key="option.value"
          v-sanitize="option.label"
          :value="option.value"
        />
      </select>

      <span
        v-if="!required && value"
        class="flex size-10 cursor-pointer items-center justify-center"
        @click="value = undefined"
        @keyup.delete="value = undefined"
      >
        <span class="">✕</span>
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.arrow-right {
  background-image: linear-gradient(45deg, transparent 50%, currentColor 50%),
    linear-gradient(135deg, currentColor 50%, transparent 50%);
  background-position: calc(100% - 20px) calc(1px + 50%),
    calc(100% - 16px) calc(1px + 50%);
  background-size: 4px 4px, 4px 4px;
  background-repeat: no-repeat;
}
</style>
