<script setup lang="ts">
import { computed, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useForm } from "vee-validate";
import * as yup from "yup";

import { toTypedSchema } from "@vee-validate/yup";
import { NCtaLoader, type ValidStateModel } from "@team-uep/n-cta-loader";
import { type ExtractGeneric, RouteNames } from "@/types";
import { useToken } from "@/composable/useToken";
import {
  frPhoneToStdPhone,
  getPhoneRegex,
  stdPhoneToFrPhone,
} from "@/helpers/phone";
import {
  useCompanySizeOptions,
  useFrequencyOptions,
  useRerOptions,
  useStationOptions,
  useTransilienOptions,
} from "@/composable/useApiList";
import { nameTest } from "@/helpers/name";
import { extractRailwaysFromList } from "@/helpers/railway";
import InputRailway from "@/components/InputRailway.vue";
import InputCheck from "@/components/InputCheck.vue";
import InputRadio from "@/components/InputRadio.vue";
import InputSelect from "@/components/InputSelect.vue";
import InputText from "@/components/InputText.vue";
import InputAutocomplete from "@/components/InputAutocomplete.vue";
import { useApi } from "@/composable/useApi";
import { getEmailRegex } from "@/helpers/email";
import { ApiError, useApiErrorParser } from "@/helpers/apiError";
import { removeAccents } from "@/helpers/accents";

const apiError = ref("");

const { t } = useI18n();
const route = useRoute();
const api = useApi();
const token = useToken();
const router = useRouter();
const { companySizeOptions } = useCompanySizeOptions(apiError);
const { frequencyOptions } = useFrequencyOptions(apiError);
const { stationOptions, stationTest, getStationId, getStationName }
  = useStationOptions(apiError);
const { rerOptions } = useRerOptions(apiError);
const { transilienOptions } = useTransilienOptions(apiError);
const apiErrorParser = useApiErrorParser();
const displayOptin = ref(true);

function createValidationSchema() {
  const communication = yup
    .boolean()
    .oneOf([true], t("form-communication-error-empty"))
    .required(t("form-communication-error-empty"));

  return yup.object({
    lastName: yup
      .string()
      .test("name", t("form-lastName-error-invalid"), nameTest)
      .required(t("form-lastName-error-empty")),
    firstName: yup
      .string()
      .test("name", t("form-firstName-error-invalid"), nameTest)
      .required(t("form-firstName-error-empty")),
    email: yup
      .string()
      .required(t("form-email-error-empty"))
      .matches(getEmailRegex(), t("form-email-error-invalid")),
    phone: yup
      .string()
      .matches(getPhoneRegex(), {
        excludeEmptyString: true,
        message: t("form-phone-error-invalid"),
      })
      .transform(frPhoneToStdPhone),
    companyName: yup.string().required(t("form-companyName-error-empty")),
    companySize: yup.string().required(t("form-companySize-error-empty")),
    railway: yup
      .array()
      .of(yup.string().required())
      .required(t("form-railway-error-empty"))
      .min(1, t("form-railway-error-empty")),
    station: yup
      .string()
      .test("station", t("form-station-error-invalid"), stationTest),
    frequency: yup.string().required(t("form-frequency-error-empty")),
    ...(displayOptin.value ? { communication } : {}),
  });
}

const { handleSubmit, setValues, setErrors } = useForm<
  ExtractGeneric<ReturnType<typeof createValidationSchema>>
>({
  validationSchema: computed(() =>
    toTypedSchema<any, any, any>(createValidationSchema()),
  ),
  initialValues: {
    email: route.query.email ? String(route.query.email) : undefined,
    railway: [],
  },
});

watch(
  token,
  async (tok?: string) => {
    if (tok) {
      try {
        const prefill = await api.getClientB2b(tok);

        // Disable optin if prefill otin is true
        if (prefill.optin) {
          displayOptin.value = false;
        }

        setValues({
          lastName: prefill.last_name,
          firstName: prefill.first_name,
          email: prefill.email,
          phone: stdPhoneToFrPhone(prefill.mobile_phone),
          companyName: prefill.company_name,
          companySize: prefill.company_size,
          railway: [...(prefill.rers || []), ...(prefill.transiliens || [])],
          station: getStationName(prefill.nearby_station),
          frequency: prefill.frequency,

          // optin is not required if prefill optin is true
          ...(prefill.optin
            ? { communication: undefined }
            : { communication: prefill.optin }),
        });
        displayOptin.value = !prefill.optin;

        // Remove optin error
        setErrors({ communication: undefined });
      }
      catch (error) {
        router.push({ name: RouteNames.B2b });
      }
    }
  },
  { immediate: true },
);

const registerLoadingState = ref<ValidStateModel>("default");

const onSubmit = handleSubmit(async (values) => {
  if (registerLoadingState.value !== "default") {
    return;
  }
  registerLoadingState.value = "progress";

  apiError.value = "";
  try {
    await api.submitB2b({
      token: token.value,
      last_name: values.lastName,
      first_name: values.firstName,
      email: values.email,
      mobile_phone: values.phone,
      company_name: values.companyName,
      company_size: values.companySize,
      rers: extractRailwaysFromList(values.railway, rerOptions.value),
      transiliens: extractRailwaysFromList(
        values.railway,
        transilienOptions.value,
      ),
      nearby_station: getStationId(values.station) || undefined,
      frequency: values.frequency,

      // Send optin only if not prefilled with true value
      ...(values.communication === !!values.communication
        ? { optin: values.communication }
        : {}),
    });
    registerLoadingState.value = "success";
  }
  catch (error: unknown | Error) {
    registerLoadingState.value = "error";
    apiError.value
      = error instanceof Error ? error.message : JSON.stringify(error);

    if (error instanceof ApiError && error.errorMessages) {
      setErrors(apiErrorParser(error.errorMessages));
    }
  }
});

function onApiResponse(actualState: ValidStateModel) {
  if (actualState === "success") {
    return router.push({ name: RouteNames.B2bSuccess });
  }

  if (actualState === "error") {
    registerLoadingState.value = "default";
  }

  return undefined;
}
</script>

<template>
  <form @submit="onSubmit">
    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="lastName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-lastName-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputText
        name="firstName"
        :input-attr="{ maxlength: '50' }"
        :label="t('form-firstName-label')"
        :uppercase="true"
        class="flex-1"
      />
    </div>

    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="email"
        :input-attr="{ maxlength: '150' }"
        :label="t('form-email-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputText
        name="phone"
        :input-attr="{
          maxlength: '150',
          placeholder: t('form-phone-placeholder'),
          type: 'tel',
        }"
        :label="t('form-phone-label')"
        :uppercase="true"
        mask="0#########"
        class="flex-1"
      />
    </div>

    <div class="mb-6 flex w-full flex-col gap-6 md:flex-row md:gap-11">
      <InputText
        name="companyName"
        :input-attr="{
          maxlength: '50',
        }"
        :label="t('form-companyName-label')"
        :uppercase="true"
        class="flex-1"
      />

      <InputSelect
        name="companySize"
        :label="t('form-companySize-label')"
        :default-option="t('form-companySize-default')"
        :options="companySizeOptions"
        :uppercase="true"
        class="flex-1"
      />
    </div>

    <InputRailway
      :rer-options="rerOptions"
      :transilien-options="transilienOptions"
      name="railway"
      class="mb-6 max-w-lg"
    >
      {{ t("form-railway-title-b2b") }}
    </InputRailway>

    <InputAutocomplete
      name="station"
      :placeholder="t('form-station-placeholder')"
      :list="stationOptions"
      :label="t('form-station-label')"
      :transform="removeAccents"
      :not-found-message="t('form-station-not-found')"
      class="label--title mb-6 max-w-lg"
    />

    <InputRadio
      name="frequency"
      :label="t('form-frequency-label')"
      :list="frequencyOptions"
      class="h--title mb-6"
    />

    <template v-if="displayOptin">
      <div class="mb-6">
        <p v-sanitize="t('form-terms-b2b')" class="text-sm" />
      </div>

      <InputCheck
        name="communication"
        value="true"
        class="optin-check mb-6 text-sm"
      >
        <span v-sanitize="t('form-communication-label-b2b')" />
      </InputCheck>
    </template>

    <div class="text-center">
      <NCtaLoader
        data-cy="submit-register"
        :button-attributes="{ type: 'submit' }"
        class="mx-auto mb-2 md:max-w-[402px] md:text-lg"
        :state="registerLoadingState"
        @finished-animation="onApiResponse"
      >
        {{ token ? t("form-update") : t("form-submit") }}
      </NCtaLoader>
    </div>

    <p v-if="apiError" class="mt-2 text-center text-sm text-red-500">
      {{ apiError }}
    </p>
  </form>
</template>

<style scoped lang="scss">
* {
  --n-primary-color: #187abf;
}

:deep(.optin-check label) {
  align-items: start !important;
  span {
    margin-top: -4.5px;
  }
}
</style>
